<template>
  <div>
    <div id="wizardStepperContainer">
      <h3 id="wizardStepperTitle">
        Aggregator & Plan Settings
      </h3>


      <div id="stepper">
        <v-stepper class="custom-header stepperComponent" height="48" v-model="wizardStep">
          <v-stepper-header>
            <v-stepper-step
              :complete="wizardStep > 1"
              step="1"
            >
              AGGREGATOR DETAILS
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="wizardStep > 2"
              step="2"
            >
              SELECT PLAN
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              SETUP PAY STRUCTURE
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </div>
    </div>

    <div id="stepContainerPanel">
      <router-view @next="nextStep" @goToStep="goToStep"/>
    </div>
  </div>
</template>

<script>
import Aggregator from "@/pages/commissions/commission-settings/steps/Aggregator";
import _ from "lodash";

export default {
  components: {Aggregator},
  data() {
    return {
      wizardStep: 1,

      routes: {
        1: 'commission-settings.aggregator',
        2: 'commission-settings.plan-select',
        3: 'commission-settings.pay-structure',
        4: 'commission-settings.completed',
      }
    };
  },
  methods: {
    goToStep(stepNumber) {
      this.wizardStep = stepNumber;

      this.$router.push({
        name: this.routes[this.wizardStep]
      });
    },
    nextStep() {
      this.wizardStep++;

      this.$router.push({
        name: this.routes[this.wizardStep]
      });
    }
  },
  mounted() {
    this.wizardStep = _.findKey(this.routes, (o) => {
      return o === this.$route.name
    })
  }
}
</script>

<style lang="scss" scoped>
#wizardStepperContainer {
  background: #FFFFFF;
  padding: 16px 32px;

  #wizardStepperTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #3A3541;
  }

  margin-bottom: 8px;
}

#stepper {
  margin-bottom: 16px;
}

.custom-header {
  box-shadow: none !important;
}

::v-deep .custom-header .v-stepper__step__step::after {
  transform: scale(0.7) !important;
}

::v-deep #pageTitleContainer {
  color: #3A3541;
  margin-bottom: 32px;

  #pageNumber {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 42px;
  }

  #pageTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    position: relative;
    bottom: 2px;
    left: 8px;
  }
}
</style>
